
import React, {useState} from "react";
import { Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image";

function ProjectPreview(props) {
    const [loaded, setLoaded] = useState(false);

    function formatProjectStatus(value) {
        if(value === 'selling') {
            return 'Now selling';
        } else if(value === 'complete') {
            return '';
        }

        return '';
    }

    var status = formatProjectStatus(props.projectStatus)

    return (
        <Link className="group" to={`/project/${props.slug.current}`}>
            <div className="block overflow-hidden relative">
                {props.previewImage && (
                    <Image
                        {...props.previewImage}
                        width={600}
                        alt={props.previewImage.alt ? props.previewImage.alt : props.title}
                        className={
                            `transition-transform duration-500 ease-in-out md:group-hover:transform md:group-hover:scale-[1.05] lazy ` +
                            (loaded && " loaded")
                        }
                        onLoad={() => setLoaded(true)}
                    />
                )}
            </div>
            <div className="flex flex-col justify-between gap-[4px]">
                <h2 className="text-[20px] md:text-6 leading-[32px] font-serif mt-6">
                    {props.title}
                </h2>

                <div className="flex flex-wrap gap-[4px]">
                    {props.projectType &&
                        props.projectType.map((type) => (
                            <div
                                className="text-[12px] text-[#666]"
                                key={type.id}
                            >
                                {type.title}
                            </div>
                        ))}

                    {status && (
                        <div className="text-[12px] text-[#666]"> - {status}</div>
                    )}
                </div>
            </div>
        </Link>
    );
}

export default React.memo(ProjectPreview);